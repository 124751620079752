import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'
import DateUtils from 'date_utils'

export default class extends Controller {
  static values = {
    eventsUrl: String,
    calendarTooltipUrl: String,

    highlightUserId: String,
    highlightDate: String,

    calendarRangeStartDate: String,
    calendarRangeEndDate: String,
  }

  static targets = [
    'calendar',
    'clickarea',
  ]

  connect() {
    if (this.hasHighlightUserIdValue && this.hasHighlightDateValue) {
      this.clickCell(this.highlightUserIdValue, this.highlightDateValue)
    }
  }

  get dates() {
    return [...this.calendarTarget.querySelectorAll('[data-col-date]')].map(cell => cell.dataset.colDate)
  }

  get userIds() {
    return [...this.calendarTarget.querySelectorAll('[data-row-user-id]')].map(row => row.dataset.rowUserId)
  }

  calendarTooltipUrl(userId, date) {
    return this.calendarTooltipUrlValue
      .replace(':user_id', userId)
      .replace(':date', date)
  }

  eventsUrl(userId) {
    const params = {
      user_ids: [userId],
      indices: [this.userIds.indexOf(userId)],
      start_date: this.calendarRangeStartDateValue,
      end_date: this.calendarRangeEndDateValue,
    }

    const url = this.eventsUrlValue
    const urlParams = new URLSearchParams(Helpers.objectToQueryString(params))

    return `${url}?${urlParams.toString()}`
  }

  clickCell(userId, date) {
    const userIdx = this.userIds.indexOf(userId)
    const dateIdx = this.dates.indexOf(date)

    const a = document.createElement('a')

    // open calendar tooltip in a popover
    a.dataset.popover = true
    a.dataset.popoverUrl = this.calendarTooltipUrl(userId, date)

    // reload events for this user if we add or edit absences
    a.dataset.turboStreamReferrer = this.eventsUrl(userId)

    const baseRow = parseInt(this.clickareaTarget.style.gridRowStart)
    const baseCol = parseInt(this.clickareaTarget.style.gridColumnStart)

    a.style.gridRow = `${baseRow + userIdx} / span 1`
    a.style.gridColumn = `${baseCol + (dateIdx * 2)} / span 2`
    a.style.pointerEvents = 'none'
    this.calendarTarget.appendChild(a)

    // wait a bit so the popover can be positioned properly
    setTimeout(() => a.click(), 100)
  }

  onCellClick(event) {
    const rect = this.clickareaTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const dateIdx = Math.floor((x / rect.width) * this.dates.length)
    const userIdx = Math.floor((y / rect.height) * this.userIds.length)

    const userId = this.userIds[userIdx]
    const date = this.dates[dateIdx]

    this.clickCell(userId, date)
  }
}
