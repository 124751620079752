class Glass {
  track(name, data) {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', '/glass')
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

    const body = {
      name: name,
      data: data,
    }

    xhr.send(JSON.stringify(body))
  }

  trackPageLoad() {
    if (!this.shouldRecordPagePerformance)
      return

    let properties = {}

    if (window.performance && window.performance.timing) {
      // https://w3c.github.io/navigation-timing/
      const timing = window.performance.timing
      if (typeof(timing.toJSON) !== 'undefined') {
        properties = timing.toJSON()
        properties.now = new Date().getTime()
      }
    }

    let browserDetails = {
      url: window.location.href,
      user_agent: navigator.userAgent,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      lang: window.lang,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    }

    this.track('page_perf', { ...properties, ...browserDetails })
  }

  trackXHR(timing) {
    if (!this.shouldRecordPagePerformance)
      return

    const properties = Object.assign({
      xhr: true,
      now: new Date().getTime(),
    }, timing)

    properties['url'] = window.location.href
    this.track('page_perf', properties)
  }

  get shouldRecordPagePerformance() {
    // record 5% of page views
    return Math.random() <= 0.05
  }
}

export default new Glass()
