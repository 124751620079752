import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'
import { Helpers } from '../../../utils/helpers'

export default class extends Controller {
  static targets = [
    'window',
    'content',
    'loading',
  ]

  static MILLISECONDS_UNTIL_SLOW_LOADING_INDICATOR = 500

  connect() {
    this.show = this.initialShow
    this.preload = false

    this.element.addEventListener('modal:open!', event => {
      const preload = (event.detail && event.detail.preload) ? true : false
      this.open(preload)
    })

    this.element.addEventListener('modal:close!', event => this.close())

    this.element.addEventListener('modal:content!', event => this.setContent(event.detail))

    this.update()
  }

  open(preload = false) {
    this.show = true

    this.slow = false
    this.preload = false

    if (preload) {
      this.preload = true
      // clear content so previous content never flashes up
      this.contentTarget.innerHTML = ''
    }

    clearTimeout(this.slowTimer)
    if (preload) {
      this.slowTimer = setTimeout(() => {
        this.slow = true
        this.update()
      }, this.constructor.MILLISECONDS_UNTIL_SLOW_LOADING_INDICATOR) // show loading indication after x elapsed seconds of no content
    }

    this.update()
  }

  close() {
    this.show = false
    this.update()
  }

  setContent(content) {
    clearTimeout(this.slowTimer)
    this.preload = false
    this.contentTarget.innerHTML = content
    this.update()
  }

  update() {
    toggle(this.element, this.show, 'flex')

    // content
    const showContent = !this.preload
    const showLoading = this.preload && this.slow

    toggle(this.loadingTarget, showLoading)
    toggle(this.contentTarget, showContent)

    toggle(this.windowTarget, showLoading || showContent)
  }

  onClick(event) {
    // When clicking outside of a form element on a mobile device,
    // this will blur the element and therefore force the on-device
    // virtual keyboard to disappear. Two reasons:
    // 1. There is a strange bug/behaviour on iOS where - when clicking into a
    //    textarea - the popping up keyboard would move the button click area up,
    //    making it almost impossible to click the damn buttons.
    // 2. Convenience: When clicking outside, the intuitive expectation
    //    seems to be that the keyboard goes away. Many native apps do this.
    if (!Helpers.deviceHasNativeFormControls())
      return

    if (event.target != document.activeElement)
      document.activeElement.blur()
  }

  onKeyDown(event) {
    // do not allow escape to close modals which are displayed immediately
    // (e.g. setup modal on new accounts etc.)
    if (event.key === 'Escape' && this.show && !this.initialShow) {
      this.close()
      event.stopImmediatePropagation()
    }
  }

  get initialShow() {
    return this.element.getAttribute('data-show') != "false"
  }
}
