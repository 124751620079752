import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

const MAX_IMAGE_SIZE = 2000

export default class extends Controller {
  static targets = [
    'mileageFieldWrapper',
    'mileageField',

    'currencySelect',
    'exchangeRateFieldWrapper',
    'exchangeRateField',
    'exchangeRateInfo',

    'totalCostFieldWrapper',
    'totalCostField',
    'date',

    'receiptFile',
    'receiptPreviewImage',
    'receiptPreviewPDF',
    'receiptPreviewEditIcon',
    'receiptPreviewPlaceholder',

    'expenseCategory',

    'reimbursableToggle',
    'isReimbursableLabel',
    'isReimbursableText',
    'nonReimbursableLabel',
    'nonReimbursableText',

    'skipApproval',
    'fullAccessButton',
    'requestButton',
  ]

  static values = {
    defaultCurrencyCode: String,
    exchangeRatesUrl: String,
  }

  connect() {
    this.isMileage = false
    this.hasDefaultCost = false
    this.hasReceiptAttached = this.element.dataset.hasReceipt === 'true'
    this.receiptIsPDF = false
    this.expenseCategoryInitialized = false

    this.update()
  }

  onExpenseCategorySet(event) {
    const selected = event.detail
    if (!selected) {
      return
    }

    this.expenseCategoryTargets.forEach(category => {
      toggle(category, category.dataset.expenseCategoryId == selected.value)
    })

    this.isMileage = selected.data['is_mileage']
    this.mileagePrice = selected.data['mileage_price']

    // set default cost always on change (i.e. expense category has been initialized, user changes expense category)
    // or during initialization only if cost field is empty (i.e. on new, but not in edit or validation re-render)
    const setDefaultCost = selected.data['has_default_cost'] &&
      (this.expenseCategoryInitialized || this.totalCostFieldTarget.value === '')

    if (setDefaultCost) {
      this.totalCostFieldTarget.value = selected.data['default_cost']
      // emit change event so currency value gets formatted
      Helpers.emit(this.totalCostFieldTarget, 'change')
    }

    // toggle display
    this.update()

    // set focus upon category change (timeout in case it needs to get currency formatted first)
    const field = this.isMileage ? this.mileageFieldTarget : this.totalCostFieldTarget
    setTimeout(() => {
      field.focus()
      field.select()
    }, 0)

    this.expenseCategoryInitialized = true
  }

  onCurrencyCodeChanged(event) {
    this.fetchAndUpdateExchangeRate()
  }

  onDateChanged(event) {
    this.fetchAndUpdateExchangeRate()
  }

  fetchAndUpdateExchangeRate() {
    const date = this.dateTarget.value
    const currencyCode = this.currentCurrencyCode

    this.exchangeRateFieldTarget.value = ''

    if (date) {
      Helpers.fetchAbortPrevious(this, 'get', this.exchangeRatesUrlValue, { query: { date: date } }).then(response => {
        if(response.ok) {
          response.json.then(json => {
            const rate = json[currencyCode]
            if (rate) {
              this.exchangeRateFieldTarget.value = +Number(rate).toFixed(6)
              this.update()
            }
          })
        }
      })
    }

    this.update()
  }

  onReadReceiptFile(event) {
    const [file] = this.receiptFileTarget.files
    if (!file)
      return

    if (file.type.startsWith('image/')) {
      this.sanitizeImage(file, (sanitizedFile) => {
        // replace file
        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(sanitizedFile)
        this.receiptFileTarget.files = dataTransfer.files
      })
    }

    const isPDF = /\.pdf/.test(file.name)
    if (!isPDF) {
      this.receiptPreviewImageTarget.innerHTML = `<img src=${window.URL.createObjectURL(file)}>`
    }

    this.hasReceiptAttached = true
    this.receiptIsPDF = isPDF
    this.update()
  }

  onToggleReimbursability(event) {
    this.update()
  }

  sanitizeImage(image, callback) {
    let img = document.createElement('img')

    img.onload = (event) => {
      const imageWidth  = img.width
      const imageHeight = img.height

      if( imageWidth > MAX_IMAGE_SIZE || imageHeight > MAX_IMAGE_SIZE ) {
        let resizedWidth, resizedHeight

        if(imageWidth > imageHeight) {
          resizedHeight = imageHeight * MAX_IMAGE_SIZE / imageWidth
          resizedWidth = MAX_IMAGE_SIZE
        } else {
          resizedWidth = imageWidth * MAX_IMAGE_SIZE / imageHeight
          resizedHeight = MAX_IMAGE_SIZE
        }

        const canvas = document.createElement('canvas')
        canvas.width = resizedWidth
        canvas.height = resizedHeight
        canvas.style.backgroundColor = 'white'

        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob((resizedBlob) => {
          const resizedFilename = image.name.replace(/\.[^.]+$/, '.jpg')
          const resizedFile = new File([resizedBlob], resizedFilename)
          callback(resizedFile)
        }, 'image/jpeg', 0.9)
      }
    }

    img.src = window.URL.createObjectURL(image)
  }

  update() {
    toggle(this.mileageFieldWrapperTarget, this.isMileage)
    toggle(this.totalCostFieldWrapperTarget, !this.isMileage)

    this.mileageFieldTarget.required = this.isMileage
    this.totalCostFieldTarget.required = !this.isMileage

    toggle(this.receiptPreviewPlaceholderTarget, !this.hasReceiptAttached)
    toggle(this.receiptPreviewEditIconTarget, this.hasReceiptAttached)
    toggle(this.receiptPreviewImageTarget, this.hasReceiptAttached && !this.receiptIsPDF)
    toggle(this.receiptPreviewPDFTarget, this.hasReceiptAttached && this.receiptIsPDF)

    toggle(this.isReimbursableLabelTarget, this.reimbursableToggleTarget.checked)
    toggle(this.isReimbursableTextTarget, this.reimbursableToggleTarget.checked)
    toggle(this.nonReimbursableLabelTarget, !this.reimbursableToggleTarget.checked)
    toggle(this.nonReimbursableTextTarget, !this.reimbursableToggleTarget.checked)

    // Determine which button to show as primary action
    if (this.hasSkipApprovalTarget) {
      this.toggleSubmit(this.fullAccessButtonTarget, this.skipApprovalTarget.checked)
      this.toggleSubmit(this.requestButtonTarget, !this.skipApprovalTarget.checked)
    }

    const isForeignCurrency = !this.isMileage && this.currentCurrencyCode !== this.defaultCurrencyCodeValue
    Helpers.toggleVisibilityAndState(this.exchangeRateFieldWrapperTarget, isForeignCurrency)
    this.exchangeRateInfoTarget.innerText = `${this.defaultCurrencyCodeValue} = 1 ${this.currentCurrencyCode}`
  }

  // ensure only one submit exists at a time (so CMD+ENTER chooses correct submit, display is ignored)
  toggleSubmit(element, show) {
    toggle(element, show)
    element.type = show ? 'submit' : 'button'
  }

  get currentCurrencyCode() {
    return this.currencySelectTarget.options[this.currencySelectTarget.selectedIndex].value
  }
}
