@keyframes blink {
  from { --tw-bg-opacity: 1; background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
  }
  to { background-color: transparent; }
}

.c-m-custom-fields .highlight-row {
    animation: blink 1s normal ease-in-out;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL21hbmFnZS9jdXN0b21fZmllbGRzX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDUyxPQUFBLGtCQUFvQixFQUFwQjtFQUFvQjtFQUMzQixLQUFLLDZCQUE2QixFQUFFO0FBQ3RDOztBQUdFO0lBQ0Usc0NBQXNDO0VBQ3hDIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL21hbmFnZS9jdXN0b21fZmllbGRzX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAa2V5ZnJhbWVzIGJsaW5rIHtcbiAgZnJvbSB7IEBhcHBseSBiZy15ZWxsb3ctMjAwOyB9XG4gIHRvIHsgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7IH1cbn1cblxuLmMtbS1jdXN0b20tZmllbGRzIHtcbiAgLmhpZ2hsaWdodC1yb3cge1xuICAgIGFuaW1hdGlvbjogYmxpbmsgMXMgbm9ybWFsIGVhc2UtaW4tb3V0O1xuICB9XG59XG4iXX0= */