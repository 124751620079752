
.c-tooltip {
  --background-color: #27272a;
  --border-color: #27272a;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));

  overflow: initial;
  max-width: 350px;
  z-index: calc(var(--z-index-top) + 1);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vdG9vbHRpcHNfY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSwyQkFBNEM7RUFDNUMsdUJBQXdDO0VBRXhDLG9CQUFnQjtFQUFoQix1QkFBZ0I7RUFBaEIsb0JBQWdCO0VBQWhCLHFCQUFnQjtFQUNoQixtQkFBcUM7RUFBckMsb0JBQXFDO0VBQXJDLGdCQUFxQztFQUFyQyxvQkFBcUM7RUFBckMsbURBQXFDOztFQUVyQyxpQkFBaUI7RUFDakIsZ0JBQWdCO0VBQ2hCLHFDQUFxQztBQUN2QyIsImZpbGUiOiIuLi9waXN0YWNoaW8vYXBwL2NvbXBvbmVudHMvcGlzdGFjaGlvL3Rvb2x0aXBzX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5jLXRvb2x0aXAge1xuICAtLWJhY2tncm91bmQtY29sb3I6IHRoZW1lKCdjb2xvcnMuZ3JheS44MDAnKTtcbiAgLS1ib3JkZXItY29sb3I6IHRoZW1lKCdjb2xvcnMuZ3JheS44MDAnKTtcblxuICBAYXBwbHkgcHktMSBweC0yO1xuICBAYXBwbHkgdGV4dC1zbSB0ZXh0LXdoaXRlIGZvbnQtbWVkaXVtO1xuXG4gIG92ZXJmbG93OiBpbml0aWFsO1xuICBtYXgtd2lkdGg6IDM1MHB4O1xuICB6LWluZGV4OiBjYWxjKHZhcigtLXotaW5kZXgtdG9wKSArIDEpO1xufVxuIl19 */