.c-manage_user_tabs_information dd p:last-child {
    margin-bottom: 0px
}
  .c-manage_user_tabs_information__link {
    display: flex;
    align-items: center;
    border-left-width: 4px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1))
}
  .c-manage_user_tabs_information__link:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1))
}
  .c-manage_user_tabs_information__link:active,
    .c-manage_user_tabs_information__link:focus {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1))
}
  .c-manage_user_tabs_information__link--current {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1))
}
  .c-manage_user_tabs_information__link--current:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1))
}
  .c-manage_user_tabs_information__link--current:focus {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1))
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3VzZXJzL3VzZXIvdGFicy9pbmZvcm1hdGlvbl9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0lBQUE7QUFBVztFQUlYO0lBQUEsYUFBaUU7SUFBakUsbUJBQWlFO0lBQWpFLHNCQUFpRTtJQUFqRSxxQkFBaUU7SUFBakUsc0JBQWlFO0lBQWpFLG1CQUFpRTtJQUFqRSxzQkFBaUU7SUFBakUsbUJBQWlFO0lBQWpFLG9CQUFpRTtJQUFqRSxnQkFBaUU7SUFDakUseUJBQTRFO0lBQTVFLG9CQUE0RTtJQUE1RTtBQURpRTtFQUNqRTtJQUFBLGtCQUE0RTtJQUE1RSw0REFBNEU7SUFBNUUsb0JBQTRFO0lBQTVFO0FBQTRFO0VBSTFFOztJQUFBLG9CQUFvQjtJQUFwQjtBQUFvQjtFQUlwQjtJQUFBLHNCQUFvRjtJQUFwRiwyREFBb0Y7SUFBcEYsa0JBQW9GO0lBQXBGLDREQUFvRjtJQUFwRixvQkFBb0Y7SUFBcEY7QUFBb0Y7RUFBcEY7SUFBQSxrQkFBb0Y7SUFBcEYsNERBQW9GO0lBQXBGLG9CQUFvRjtJQUFwRjtBQUFvRjtFQUdsRjtJQUFBLG9CQUFvQjtJQUFwQjtBQUFvQiIsImZpbGUiOiJhcHAvY29tcG9uZW50cy91c2Vycy91c2VyL3RhYnMvaW5mb3JtYXRpb25fY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLW1hbmFnZV91c2VyX3RhYnNfaW5mb3JtYXRpb24ge1xuICBkZCBwOmxhc3QtY2hpbGQge1xuICAgIEBhcHBseSBtYi0wO1xuICB9XG5cbiAgJl9fbGluayB7XG4gICAgQGFwcGx5IGJvcmRlci1sLTQgcHgtMyBweS0yIGZsZXggaXRlbXMtY2VudGVyIHRleHQtc20gZm9udC1tZWRpdW07XG4gICAgQGFwcGx5IGJvcmRlci10cmFuc3BhcmVudCB0ZXh0LWdyYXktOTAwIGhvdmVyOmJnLWdyYXktNTAgaG92ZXI6dGV4dC1ncmF5LTkwMDtcblxuICAgICY6YWN0aXZlLFxuICAgICY6Zm9jdXMge1xuICAgICAgQGFwcGx5IHRleHQtZ3JheS05MDA7XG4gICAgfVxuXG4gICAgJi0tY3VycmVudCB7XG4gICAgICBAYXBwbHkgYmctYmx1ZS01MCBib3JkZXItYmx1ZS01MDAgdGV4dC1ibHVlLTcwMCBob3ZlcjpiZy1ibHVlLTUwIGhvdmVyOnRleHQtYmx1ZS03MDA7XG5cbiAgICAgICY6Zm9jdXMge1xuICAgICAgICBAYXBwbHkgdGV4dC1ibHVlLTcwMDtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */